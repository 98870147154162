.menu {
  background: #fafafa;
  // DISABLE VERTICAL BAR IN TOP LEFT HEADER
  // border-right: 1px solid;
  margin-right: 18px;
  z-index: 9999;

  .dark-theme & {
    background-color: transparent;
  }

  @media #{$media-size-phone} {
    position: absolute;
    top: 68px;
    right: 0;
    border: none;
    margin: 0;
    padding: 5px 10px;
    width: 100%;
    background-color: rgba(#252627, .9)!important;

    &__inner li {
      margin-left: auto!important;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    font-size: 1rem;
    list-style: none;

    li {
      margin: 0 12px;
    }

    @media #{$media-size-phone} {
      flex-direction: column;
      align-items: flex-start;
      padding: 0;

      li {
        margin: 0;
        padding: 5px;
      }
    }
  }

  &-trigger {
    width: 24px;
    height: 24px;
    fill: currentColor;
    margin-left: 10px;
    cursor: pointer;
    display: none;

    @media #{$media-size-phone} {
      display: block;
    }
  }

  a {
    display: inline-block;
    margin-right: 15px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.logo__text,
.menu__inner {
	position: relative;
	bottom: -2px;
}

.content {
	margin: 0 auto;
	max-width: 800px;
	padding: 1rem;

	main {
		padding: 20px 15px 30px;
		background-color: rgba(#252627, .9);
	}
}

@media screen and (max-width: 680px)  {
	.content, .footer__content {
		font-size: .9em;
	}
	h1 {
		font-size: 1.5rem;
	}
}

body.dark-theme, .footer__content
{
	color: #ffc700;
	text-shadow: 3px 3px 1px black;
}


